import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardMedia,
  IconButton,
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Data from './Data.json';

export default function Card2() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsToShow = 4;
  const autoScrollInterval = 3000; // Intervalo de desplazamiento automático en milisegundos

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? Data.length - cardsToShow : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === Data.length - cardsToShow ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, autoScrollInterval);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
  }, []);

  return (
    <div className="flex flex-col items-center">
      <Typography variant="h4" align="center" className="mt-12">
        Servicios
      </Typography>
      <div className="flex justify-center items-center mt-8 relative w-full">
        <IconButton
          onClick={handlePrev}
          className="absolute left-0 transform -translate-y-1/2 z-10"
        >
          <ArrowBackIos />
        </IconButton>
        <div className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide w-full">
          {Data.slice(currentIndex, currentIndex + cardsToShow).map((item, index) => (
            <div key={index} className="flex-none w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2 snap-center">
              <Card className="max-w-sm p-2 mb-8 mx-auto" variant="outlined">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.img}
                    alt="MR.Abogado"
                    className="object-contain h-full max-h-40"
                  />
                  <CardContent className="h-48 overflow-hidden">
                    <div className="h-full overflow-y-auto">
                      <Typography gutterBottom variant="h6" component="div" className="text-lg font-semibold">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" className="line-clamp-3">
                        {item.des}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" className="line-clamp-3">
                        {item.etiquetas}
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </div>
        <IconButton
          onClick={handleNext}
          className="absolute right-0 transform -translate-y-1/2 z-10"
        >
          <ArrowForwardIos />
        </IconButton>
      </div>
    </div>
  );
}