import Appbar from "./Componentes/Menu/Appbar";
import Info from "./Componentes/Informacion/Info";
import Cartas from "./Componentes/Card/Cards";
import Card2 from "./Componentes/Card/Card2";
import { Footer } from "./Componentes/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Appbar/>
      <Info/>
      <Card2/>
      {/* <Cartas/> */}
      <Footer/>
    </div>
  );
}

export default App;
