import React from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';

export default function Info() {
    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" component="h2" gutterBottom>
                    Nuestros Servicios
                </Typography>

                {/* Servicio 1: Defensa Penal */}
                <Box my={4}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" component="h3">
                                Defensa Penal
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Representación en juicios por amenazas, demandas, fraudes, asistencia a víctimas y mucho más.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src="/img/Foto1.jpg"
                                alt="Defensa Penal"
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* Servicio 2: Trámites de Compraventa */}
                <Box my={4}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <img
                                src="/img/Foto2.jpg"
                                alt="Trámites de Compraventa"
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" component="h3">
                                Trámites de Compraventa
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Asesoría en la compra y venta de carros, motos y casas.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Servicio 3: Divorcio */}
                <Box my={4}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" component="h3">
                                Divorcio
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Trámites de divorcio por mutuo consentimiento, vida intolerable o separación de uno o más años.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src="/img/Foto3.jpeg"
                                alt="Divorcio"
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* Servicio 4: Escrituras y Herencias */}
                <Box my={4}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <img
                                src="/img/Foto4.webp"
                                alt="Escrituras y Herencias"
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" component="h3">
                                Escrituras y Herencias
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Asesoría en la redacción de escrituras y en la gestión de herencias.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Servicio 5: Todos los Trámites */}
                <Box my={4} textAlign="center">
                    <Typography variant="h5" component="h3">
                        ¡Te ayudamos con todos los trámites!
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Nos especializamos en todos los trámites legales para ofrecerte un servicio completo y personalizado.
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}
