import React from "react";
import { Grid, TextField, Button, Typography, Container, Box } from '@mui/material';
import * as Yup from "yup";
import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const Contact = () => {
  const initialFormValues = {
    problem: "",
    device: "",
    contact: "",
    phone_number: "50378081307"
  };

  const validationSchema = Yup.object().shape({
    problem: Yup.string().required("Por favor, escribe tu nombre."),
    device: Yup.string().required("Por favor, ingresa el detalle."),
    contact: Yup.string()
      .matches(/^\+?[0-9\s]+$/, "Por favor, ingresa un número de contacto válido.")
      .required("Por favor, ingresa tu número de contacto."),
    phone_number: Yup.string().required("Por favor, ingresa tu número de teléfono.")
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = {
        Nombre: values.problem,
        Detalle: values.device,
        Contacto: values.contact,
      }
      const data = {phone_number: values.phone_number, data: formData};
      const response = await fetch('https://api.bluefoxsv.com/v1/simplequote', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      console.log(JSON.stringify(responseData));

      Swal.fire({
        title: 'Enviado!',
        text: 'Pronto recibirá un mensaje del primer ejecutivo disponible.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });

      resetForm();
    } catch (error) {
      console.error(error.message);

      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh' 
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Box 
            textAlign={{ xs: 'center', lg: 'left' }} 
            mt={{ xs: 8, lg: 0 }}
          >
            <Typography variant="h3" component="h3" color="white" gutterBottom>
              ¡Asesoría legal gratuita a tu alcance!
            </Typography>
            <Typography variant="body1" color="white" gutterBottom>
              10 Años de Experiencia en Servicios Jurídicos
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values }) => (
              <Form>
                <Box sx={{ maxWidth: 'lg', mx: 'auto', p: 4, borderRadius: 2, boxShadow: 3, backgroundColor: 'rgba(255, 255, 255)' }}>
                  <Field
                    as={TextField}
                    fullWidth
                    margin="normal"
                    id="problem"
                    name="problem"
                    label="Nombre"
                    variant="outlined"
                    value={values.problem}
                    onChange={handleChange}
                    placeholder="Describe el problema"
                    InputProps={{
                      style: { backgroundColor: 'white', color: 'black' }
                    }}
                    helperText={<ErrorMessage name="problem" component="div" style={{ color: 'red' }} />}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    margin="normal"
                    id="device"
                    name="device"
                    label="Detalles"
                    variant="outlined"
                    value={values.device}
                    onChange={handleChange}
                    placeholder="Tipo de dispositivo"
                    InputProps={{
                      style: { backgroundColor: 'white', color: 'black' }
                    }}
                    helperText={<ErrorMessage name="device" component="div" style={{ color: 'red' }} />}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    margin="normal"
                    id="contact"
                    name="contact"
                    label="Número de Contacto"
                    variant="outlined"
                    value={values.contact}
                    onChange={handleChange}
                    inputProps={{ maxLength: 8 }}
                    placeholder="Ej: +503 1234-5678"
                    InputProps={{
                      style: { backgroundColor: 'white', color: 'black' }
                    }}
                    helperText={<ErrorMessage name="contact" component="div" style={{ color: 'red' }} />}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 4 }}
                  >
                    Enviar
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;
