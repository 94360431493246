import React from 'react';
import { Box, Container, Typography, Grid, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer = () => {
    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: '#8B4513',
                color: 'white',
                mt: 8,
                py: 4,
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Acerca de Nosotros
                        </Typography>
                        <Typography variant="body2">
                        Nuestro equipo de profesionales está comprometido con la defensa de los derechos de nuestros clientes y la búsqueda de soluciones legales efectivas.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Formas de Contacto
                        </Typography>
                        <Typography variant="body2">
                            Correo: info@auxiliojuridico.com
                        </Typography>
                        <Typography variant="body2">
                            Teléfono: +(503)7306-1238
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Visita nuestras redes sociales
                        </Typography>
                        <Box>
                            <IconButton
                                href="https://facebook.com"
                                target="_blank"
                                color="inherit"
                            >
                                <FacebookIcon />
                            </IconButton>
                            <IconButton
                                href="https://twitter.com"
                                target="_blank"
                                color="inherit"
                            >
                                <TwitterIcon />
                            </IconButton>
                            <IconButton
                                href="https://instagram.com"
                                target="_blank"
                                color="inherit"
                            >
                                <InstagramIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom>
                            Abogados Unidos de El Salvador
                        </Typography>
                        <Typography variant="body2">
                            Asesoría Jurídica
                        </Typography>
                        <Typography variant="body2">
                            Casos: Penales, Familia, Civiles
                        </Typography>
                        <Typography variant="body2">
                            Sertrasen, etc.
                        </Typography>
                    </Grid>
                </Grid>
                <Box textAlign="center" pt={5} pb={2}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} ABOGADOS UNIDOS DE EL SALVADOR
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
